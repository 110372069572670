import HomeHeadline from '../modules/HomeHeadline.js'
import { setMinHeight, unsetMinHeight } from './handleMinHeight.js'
import { setCarousel, initCarousel } from './handleCarousel.js'

const carousel = setCarousel()

export default function setHomePage(mobileBreakpoint) {
	initHomeHeadline()
	setCarouselAndHeight(mobileBreakpoint)
	setHeightOnResizeEvent(mobileBreakpoint)
}

// initHomeHeadline() block

function initHomeHeadline() {
	const homeHeadlineEl = document.querySelector('[data-headline]')
	if (homeHeadlineEl) {
		const homeHeadline = new HomeHeadline(homeHeadlineEl)
		homeHeadline.prepareTexts()
		observingHomeHeadlineEl(homeHeadlineEl, homeHeadline)
	}
}

function observingHomeHeadlineEl(homeHeadlineEl, homeHeadline) {
	const options = { root: null, threshold: 1 }
	const observer = new IntersectionObserver(intersectingEntries, options)
	observer.observe(homeHeadlineEl)

	function intersectingEntries(entries) {
		const homeHeadlineEntry = entries[0]
		const isHomeHeadlineEntryIntersecting = homeHeadlineEntry.isIntersecting
		if (isHomeHeadlineEntryIntersecting) startOnceHomeHeadline(homeHeadline)
	}

	function startOnceHomeHeadline(homeHeadline) {
		homeHeadline.init()
		observer.unobserve(homeHeadlineEl)
	}
}

// setCarouselAndHeight() block

function setCarouselAndHeight(mobileBreakpoint) {
	const isMobile = isMobileCheck(mobileBreakpoint)
    if (!isMobile) {
		initCarousel(carousel)
		addMinHeight()
	}
}

function isMobileCheck(mobileBreakpoint) {
	const windowWidth = window.innerWidth
    const isMobile = windowWidth < mobileBreakpoint ? true : false
	return isMobile
}

function addMinHeight() {
	const { carouselSectionEl, heroSectionEl } = getCarouselAndHeroSection()
	setMinHeight(carouselSectionEl, heroSectionEl)
}

function removeMinHeight() {
	const { carouselSectionEl, heroSectionEl } = getCarouselAndHeroSection()
	unsetMinHeight(carouselSectionEl, heroSectionEl)
}

function getCarouselAndHeroSection() {
	const carouselSectionEl = document.querySelector('[data-carousel-home]')
	const heroSectionEl = document.querySelector('[data-hero-home]')

	return { carouselSectionEl, heroSectionEl }
}

// setHeightOnResizeEvent() block

function setHeightOnResizeEvent(mobileBreakpoint) {
    window.addEventListener('resize', () => setHeightOnResize(mobileBreakpoint))
}

function setHeightOnResize(mobileBreakpoint) {
	const isMobile = isMobileCheck(mobileBreakpoint)
	isMobile ? removeMinHeight() : addMinHeight()
}