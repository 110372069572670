import { setCursorPosition, getCursorOverEl, setCursorAttribute } from '../helpers/cursor.js'

class Cursor {
	constructor(cursor) {
		this.cursor = cursor
	}

	add() {
		document.addEventListener('mousemove', this.traceCursor.bind(this))
		document.addEventListener('mouseover', this.changeCursor.bind(this))
	}

	remove() {
		document.removeEventListener('mousemove', this.traceCursor.bind(this))
		document.removeEventListener('mouseover', this.changeCursor.bind(this))
	}

	traceCursor(event) {
		setCursorPosition(this.cursor, event)
	}

	changeCursor(event) {		
		const cursorOverElString = getCursorOverEl(event)
		setCursorAttribute(this.cursor, cursorOverElString)
	}
}

export default Cursor