export async function getDataByApiUrl(url) {
	const fetchedData = await fetch(url)
	const fetchedDataJson = await fetchedData.json()

	if (fetchedDataJson.code && fetchedDataJson.message) {
		const error = new Error(fetchedDataJson.message)
		throw error
	}

	return fetchedDataJson
}

export function showErrorMessage(message) {
	console.log(message)
}