import { fetchTexts, showIs, thenShowText, thenShowTexts } from '../helpers/homeHeadline.js'

class HomeHeadline {
	constructor(headline) {
		this.headline = headline
		this.timeout = 1600
		this.texts = []
	}

	async init() {
		await showIs(this.headline, this.timeout)
		await thenShowText(this.headline, this.timeout)
		await thenShowTexts(this.headline, this.texts, this.timeout)	
	}

	async prepareTexts() {
		this.texts = await fetchTexts()
	}
}

export default HomeHeadline