import { getDataByApiUrl, showErrorMessage } from '../helpers/helpers.js'

function getHeroElements(hero) {
	const heroImageEl = hero.querySelector('[data-hero-single-work-image]')
	const heroHeadlineEl = hero.querySelector('[data-hero-single-work-headline]')
	const heroHeadlineHeadingEl = heroHeadlineEl.querySelector('h1')

	return { heroImageEl, heroHeadlineEl, heroHeadlineHeadingEl }
}

export async function fetchBackgroundImages() {
	const workId = getSingleWorkPageId()
	const workIdApiUrl = `${window.location.origin}/wp-json/wp/v2/work/${workId}`

	try {
		const fetchedData = await getDataByApiUrl(workIdApiUrl)
		const backgroundImages = getBackgroundImages(fetchedData)

		return backgroundImages
	} catch (error) {
		showErrorMessage(error.message)
	}
}

export async function fetchBackgroundImage(currentImageId) {
	const imageIdApiUrl = `${window.location.origin}/wp-json/wp/v2/media/${currentImageId}`

	try {
		const fetchedData = await getDataByApiUrl(imageIdApiUrl)
		const backgroundImageUrl = getBackgroundImageUrl(fetchedData)

		return backgroundImageUrl
	} catch (error) {
		showErrorMessage(error.message)
	}
}

export async function setBackgroundImage(hero, backgroundImages) {
	if (backgroundImages) {
		const bgImageIdForCurrentWidth = getBgImageForCurrentWidth(backgroundImages)
		const backgroundImageUrl = await fetchBackgroundImage(bgImageIdForCurrentWidth)
		if (backgroundImageUrl) setHeroImageElBackground(hero, backgroundImageUrl)
	}
}

function getSingleWorkPageId() {  
	const bodyClasses = document.body.className
	const postIdMatch = /postid-(\d+)/
	const pageId = bodyClasses.match(postIdMatch)[1]

	return pageId
}

function getBackgroundImages(fetchedData) {
	const fetchedDataBackgroundImages = fetchedData.acf.background_images
	const mobileBackgroundImage = fetchedDataBackgroundImages.background_image_mobile
	const tabletBackgroundImage = fetchedDataBackgroundImages.background_image_tablet
	const desktopBackgroundImage = fetchedDataBackgroundImages.background_image_desktop

	return {
		mobileBackgroundImage,
		tabletBackgroundImage,
		desktopBackgroundImage
	}
}

function getBackgroundImageUrl(fetchedData) {
	const backgroundImageUrl = fetchedData.source_url
	return backgroundImageUrl
}

export function getBgImageForCurrentWidth(backgroundImages) {
	const { mobileBackgroundImage, tabletBackgroundImage, desktopBackgroundImage } = backgroundImages
	const windowWidth = window.innerWidth

	if (windowWidth <= 768) {
		return mobileBackgroundImage
	} else if (windowWidth <= 1024) {
		return tabletBackgroundImage
	} else {
		return desktopBackgroundImage
	}
}

export function setHeroImageElBackground(hero, backgroundImage) {
	const { heroImageEl } = getHeroElements(hero)
	heroImageEl.style.backgroundImage = `url("${backgroundImage}")`
}

export function setHeadlineMarginTop(hero) {
	const { heroHeadlineEl } = getHeroElements(hero)
	const { heroHeadlineHeadingEl } = getHeroElements(hero)
	const heroHeadingHeight = heroHeadlineHeadingEl.clientHeight

	heroHeadlineEl.style.marginTop = `-${heroHeadingHeight}px`
}